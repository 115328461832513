import { isRatio } from '@canalplus/mycanal-commons';
import { SafetyCodeContext } from '@canalplus/types-acm';
import { Tracking } from '@dce-front/hodor-types/api/v2/common/dto/tracking/definitions';
import { ApiV2PageGabaritList } from '@dce-front/hodor-types/api/v2/page/dtos/display_templates/gabarit_list/definitions';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import LoadableAdultDisclaimerConnected from '../../../components/AdultDisclaimer/LoadableAdultDisclaimerConnected';
import ErrorTemplate from '../../../components/Error/ErrorTemplate';
import Spinner from '../../../components/Spinner/Spinner';
import { useAppDispatch } from '../../../helpers/hooks/useAppDispatch';
import { useOnFocusable } from '../../../helpers/hooks/useOnFocusable';
import { useQueryTemplate } from '../../../helpers/hooks/useQueryTemplate/useQueryTemplate';
import {
  getFeatureToggleLiveTvFavorites,
  headerLevelPageListSelector,
} from '../../../selectors/application/application-selectors';
import { FromProp } from '../../../server/modules/fetchWithQuery/types';
import { FetchDetails, FetchRequestTypes } from '../../../services/types';
import { setFirstLevelPageList } from '../../../store/reducers/actions/Application-actions';
import { adultBrowsingSelector } from '../../../store/slices/adult-selectors';
import { hasUserDataCollectedSelector } from '../../../store/slices/user-selectors';
import { LocationStateContext } from '../../../typings/routing';
import GabaritListTemplate from './GabaritListTemplate';

export type GabaritListContainerProps = {
  url: string;
  onClickParameters?: FetchDetails['onClickParameters'];
  onFocusable?: () => void;
  routingContext?: LocationStateContext;
} & FromProp;

function GabaritListContainer({
  from,
  onClickParameters,
  onFocusable,
  routingContext,
  url,
}: GabaritListContainerProps): JSX.Element {
  const dispatch = useAppDispatch();
  const featToggleLiveTvFavorites = useSelector(getFeatureToggleLiveTvFavorites);
  const hasUserDataCollected = useSelector(hasUserDataCollectedSelector);
  const isAdultBrowsing = useSelector(adultBrowsingSelector);
  const headerLevelPageList = useSelector(headerLevelPageListSelector);

  const isLiveGridFavoritesEnabled = featToggleLiveTvFavorites && hasUserDataCollected;

  const [{ isLoading, data, error }] = useQueryTemplate<ApiV2PageGabaritList & { tracking?: Tracking }>(
    url,
    {
      from,
      onClickParameters,
      routingContext,
      template: FetchRequestTypes.GabaritList,
    },
    undefined,
    { isLiveGridFavoritesEnabled }
  );

  const { currentPage, rubriques, displayParameters } = data || {};
  const { imageRatio } = displayParameters || {};

  const showAdultDisclaimer = currentPage?.adult && !isAdultBrowsing;

  useOnFocusable(onFocusable, !!data);

  useEffect(() => {
    if (
      data &&
      typeof data.currentPage?.path === 'string' &&
      headerLevelPageList.includes(data.currentPage.path) &&
      data.rubriques
    ) {
      dispatch(
        setFirstLevelPageList(
          data.rubriques
            .filter((rubrique) => typeof rubrique.path === 'string')
            .map((rubrique) => rubrique.path as string)
        )
      );
    }
  }, [data, headerLevelPageList, dispatch]);

  if (isLoading) {
    return <Spinner />;
  }

  if (error) {
    return <ErrorTemplate error={error} />;
  }

  return showAdultDisclaimer ? (
    <LoadableAdultDisclaimerConnected safetyCodeContext={SafetyCodeContext.Parental} />
  ) : (
    <GabaritListTemplate
      title={currentPage?.displayName}
      rubrics={rubriques}
      onFocusable={onFocusable}
      imageRatio={isRatio(imageRatio) ? imageRatio : undefined}
    />
  );
}

export default GabaritListContainer;
